import { AuthView, stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getIsRealNameRequired = (state: AllState) => localState(state).isRealNameRequired

export const getNewsletterSubscription = (state: AllState) =>
  localState(state).newsletterSubscription

export const getIsAuthModalOpen = (state: AllState) => localState(state).isAuthModalOpen || false

export const getAuthView = (state: AllState) => localState(state).authView

export const getIsSelectTypeLogin = (state: AllState) =>
  getAuthView(state) === AuthView.SelectTypeLogin

export const getIsBusinessRegistrationLinkVisible = (state: AllState) =>
  localState(state).isBusinessRegistrationLinkVisible

export const getExternalRegisterData = (state: AllState) => localState(state).externalRegisterData

export const getIsFacebookIsInitialized = (state: AllState) =>
  localState(state).isFacebookIsInitialized || false

export const getIsBusinessAuth = (state: AllState) => localState(state).isBusiness || false

export const getTwoFactorLoginData = (state: AllState) => localState(state).twoFactorLoginData
